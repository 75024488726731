import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "designing-with-color"
    }}>{`Designing with Color`}</h2>
    <p>{`Using color in a manner that allows all users to understand designs is crucial to creating an
inclusive design. Choose colors that allow optimal usability within your application. Color should
be used to enhance understanding rather than as the primary means to communicate information.
Consider the following when designing with color:`}</p>
    <ul>
      <li parentName="ul">{`Using color as the sole means of communicating information is not as clear as using multiple
methods of communicating information.`}</li>
      <li parentName="ul">{`Different user types may not be able to recognize information that is only conveyed using a single
sensory characteristic such as color.`}</li>
    </ul>
    <h2 {...{
      "id": "contrast-ratios"
    }}>{`Contrast Ratios`}</h2>
    <p>{`All text, images of text, and icons should provide sufficient contrast so that users are able to
navigate and interact with your interface easily. Contrast is displayed as a ratio that ranges from
1:1 to 21:1. The difference between the two numbers indicates how much relative luminance (the
relative brightness of any point in a colorspace) there is between the foreground and background
colors. Contrast ratio requirements ensure that foreground content can easily be seen in front of a
background color.`}</p>
    <p>{`Color-contrast ratios will vary depending on font size and weight, and will generally be lower as
text gets larger because the strokes on larger letters are wider and easier to read at a
lower-contrast ratio.`}</p>
    <p>{`Ensuring that contrast ratios are properly met will allow all users to read and understand content.
These W3C guidelines apply to contrast ratios:`}</p>
    <ul>
      <li parentName="ul">{`Text that is 14 pt or below must meet a 4.5:1 contrast ratio.`}</li>
      <li parentName="ul">{`Text that is 14 pt and bold or larger than 18 pt must meet a 3:1 contrast ratio.`}</li>
      <li parentName="ul">{`Contrast ratios also apply to text, non-text content, and images of text.`}</li>
      <li parentName="ul">{`Non-text content must meet a 3:1 contrast ratio.`}</li>
    </ul>
    <h2 {...{
      "id": "dos-and-donts"
    }}>{`Do's and Don'ts`}</h2>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Use colors that meet contrast ratios for all text, non-text content, and image of text.`}</li>
        <li parentName="ul">{`Follow color guidelines laid out in Canvas.`}</li>
      </ul>
    </Do>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "435px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/319c27d6e2639280aba0a431ac06bced/330eb/color-contrast-do.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.1081081081081%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The text in this image does meet color contrast requirements",
            "title": "The text in this image does meet color contrast requirements",
            "src": "/static/319c27d6e2639280aba0a431ac06bced/330eb/color-contrast-do.png",
            "srcSet": ["/static/319c27d6e2639280aba0a431ac06bced/1efb2/color-contrast-do.png 370w", "/static/319c27d6e2639280aba0a431ac06bced/330eb/color-contrast-do.png 435w"],
            "sizes": "(max-width: 435px) 100vw, 435px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Use color as the sole means of communicating information.`}</li>
        <li parentName="ul">{`Alter colors of logotypes; they are not required to meet color contrast requirements.`}</li>
      </ul>
    </Dont>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "435px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2c40d9910054e6904c1344bb8b14eeb3/330eb/color-contrast-dont.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.1081081081081%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The text in this image does not meet color contrast requirements",
            "title": "The text in this image does not meet color contrast requirements",
            "src": "/static/2c40d9910054e6904c1344bb8b14eeb3/330eb/color-contrast-dont.png",
            "srcSet": ["/static/2c40d9910054e6904c1344bb8b14eeb3/1efb2/color-contrast-dont.png 370w", "/static/2c40d9910054e6904c1344bb8b14eeb3/330eb/color-contrast-dont.png 435w"],
            "sizes": "(max-width: 435px) 100vw, 435px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      